<template>
  <div class="page inPage cellPage">
    <div class="position">
      <i class="lee-icon lee-icon-arrow-left" @click="back"></i>
      <div class="title">Cell</div>
    </div>
    <div class="pageBody">
      <lee-cell-group title="基础用法">
        <lee-cell title="单元格" footer="内容"></lee-cell>
        <lee-cell title="单元格" footer="内容" label="描述信息"></lee-cell>
      </lee-cell-group>
      <lee-cell-group title="卡片风格" inset>
        <lee-cell title="单元格" footer="内容"></lee-cell>
        <lee-cell title="单元格" footer="内容" label="描述信息"></lee-cell>
      </lee-cell-group>
      <lee-cell-group title="展示图标">
        <lee-cell title="单元格" footer="内容" icon="map"></lee-cell>
        <lee-cell title="单元格" footer="内容" icon="picture"></lee-cell>
      </lee-cell-group>
      <lee-cell-group title="展示箭头">
        <lee-cell title="无限局域网" footer="未连接" is-link icon="lock"></lee-cell>
        <lee-cell title="单元格" footer="内容" is-link></lee-cell>
        <lee-cell title="单元格" footer="内容" is-link arrow-direction="down"></lee-cell>
      </lee-cell-group>
      <lee-cell-group title="页面导航">
        <lee-cell title="URL跳转" is-link url="http://v1.leeao82.com"></lee-cell>
        <lee-cell title="路由跳转到HOME" to="/" is-link></lee-cell>
      </lee-cell-group>
      <lee-cell-group title="使用插槽">
        <lee-cell title="飞行模式">
          <template #footer>
            <lee-switch v-model="kg"></lee-switch>
          </template>
        </lee-cell>
        <lee-cell footer="内容" is-link>
          <template #title>
            <span class="custom-title">单元格</span>
            <lee-tag type="primary">标签</lee-tag>
          </template>
        </lee-cell>
        <lee-cell title="通知" is-link>
          <template #footer>
            <lee-badge :content="3"/>
          </template>
        </lee-cell>
        <lee-cell title="照片" icon="picture">
          <template #right-icon>
            <lee-icon name="search"/>
          </template>
        </lee-cell>
      </lee-cell-group>
    </div>
    <div class="pageFooter"><img src="@/assets/logo.png"></div>
  </div>
</template>
<script setup>
import {ref} from 'vue'
import {useRouter} from 'vue-router'


const router = useRouter()
const back = () => {
    router.push('/')
}
const kg=ref(true)

</script>
<style>


</style>
